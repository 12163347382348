import RecruitingSuppliersComponent from 'src/components/Institucional/RecruitingSuppliers'
import Seo from 'src/components/seo/Seo'

function RecruitingSuppliersPage() {
  return (
    <>
      <Seo title="Institucional | Decathlon" />
      <RecruitingSuppliersComponent />
    </>
  )
}

export default RecruitingSuppliersPage
